<template>
  <div>
    <back />
    <div class="container">
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">分类名称</div>
        </el-col>
        <el-col :span="5">
          <div class="value" :title="detail.categoryName">{{ detail.categoryName }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">申请供应商</div>
        </el-col>
        <el-col :span="5">
          <div class="value" :title="detail.createdUserName">{{ detail.createdUserName }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">申请时间</div>
        </el-col>
        <el-col :span="5">
          <div class="value" :title="detail.createdTime">{{ detail.createdTime }}</div>
        </el-col>
      </el-row>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">审核状态</div>
        </el-col>
        <el-col :span="5">
          <div class="value" :title="statusArr[detail.auditStatus]">{{ statusArr[detail.auditStatus] }}</div>
        </el-col>
        <el-col :span="3">
          <div class="label">审核时间</div>
        </el-col>
        <el-col :span="5">
          <div class="value" :title="detail.auditDate">{{ detail.auditDate }}</div>
        </el-col>
      </el-row>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label text-red">不通过原因</div>
        </el-col>
        <el-col :span="21">
          <div class="value text-red">{{ detail.auditMark }}</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "typeDetail",
  data() {
    return {
      detail: {},
      statusArr: ["待审核", "审核通过", "审核不通过"],
    };
  },
  created() {
    this.getProductInfoQuery();
  },
  methods: {
    getProductInfoQuery() {
      const categoryId = this.$route.query.categoryId;
      this.$axios
        .get(this.$api.categoryQuery, {
          params: {
            categoryId: categoryId,
          },
        })
        .then((res) => {
          if (res.data.code == 100) {
            this.detail = res.data.result;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 30px 30px 0;
  border-radius: 6px;
  color: #1a1a1a;
  max-width: 1632px;

  .row {
    margin-bottom: 30px;

    .label {
      text-align: right;
    }

    .value {
      height: 46px;
      line-height: 46px;
      padding: 0 20px;
      border-radius: 10px;
      border: 1px solid #1a03100e;
      margin-left: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-red {
      color: #eb2500;
    }
  }
}
</style>